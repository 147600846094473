import React from 'react';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { RICH_TEXT_DEFAULT_WIDGET_OPTIONS } from './utils';
import { TextWidgetContainer } from './component/TextWidgetSection';
import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { getTextAndBackgroundColor } from '../RichTextImage/utils';

const RichTextWidget = (props: any) => {
  const { widgetContextState } = useWidgetDndContextData();
  const { widgets, activeWidgetId } = widgetContextState;
  const isMobile = isMobileDevice();

  const activeWidget = widgets[(props as any).id];
  const activeWidgetProps = activeWidget?.props;

  const widgetOptions = {
    ...RICH_TEXT_DEFAULT_WIDGET_OPTIONS,
    ...props.widgetOptions,
    ...activeWidgetProps,
  };

  const getStyleObject = () => {
    const baseConfig = widgetOptions?.baseConfig;
    const sectionConfig = widgetOptions?.textSection;
    const selectedColorDetails = getTextAndBackgroundColor(widgetOptions);

    const style = {
      justifyContent: isMobile ? '' : sectionConfig?.position,
      width: widgetOptions?.baseConfig?.makeFullWidth ? '100%' : '90%',
      margin: '0 auto',
      padding: isMobile
        ? `${baseConfig?.paddingMobileY ?? 0}px 28px`
        : `${baseConfig?.paddingDesktopY ?? 0}px 68px`,
      marginTop: baseConfig?.marginTop,
      marginBottom: baseConfig?.marginBottom,
      border: baseConfig?.showBorder
        ? `${baseConfig?.borderThicness}px solid ${baseConfig?.borderColor}`
        : '',
      background: selectedColorDetails.background,
    };
    return style;
  };

  return (
    <>
      {widgetOptions?.isVisible ? (
        <div
          style={getStyleObject()}
          className={`${!widgetOptions?.baseConfig?.makeFullWidth ? 'tw-max-w-[1500px]' : ''} tw-w-[100%] ${isMobile ? 'tw-px-[28px]' : 'tw-px-[68px]'} tw-flex`}
        >
          <TextWidgetContainer widgetOptions={widgetOptions} widgetId={activeWidgetId} />
        </div>
      ) : null}
    </>
  );
};

export default RichTextWidget;
